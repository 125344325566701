import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import videojs from "video.js"
import "@videojs/http-streaming"
import hlsQualitySelector from "jb-videojs-hls-quality-selector"

videojs.registerPlugin("hlsQualitySelector", hlsQualitySelector)

export default class extends Controller {
  static targets = ["player"]
  static outlets = ["vimeo-player"]
  static values = {
    vimeoId: String,
    thumbnailUrl: String,
    autoplay: Boolean,
    nextChapterUrl: String,
  }

  connect() {
    this.setupPlayer()
    this.setupEventHandlers()
    this.fetchAndLoadVideoData()

    if (this.autoplayValue) {
      this.play()
    }
  }

  setupPlayer() {
    this.player = videojs(this.playerTarget, {
      controls: true,
      fluid: true,
      playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2],
      poster: this.thumbnailUrlValue,
      html5: {
        vhs: { overrideNative: true },
        nativeVideoTracks: false,
        nativeAudioTracks: false,
        nativeTextTracks: false,
      },
    })

    this.player.ready(() => {
      if (videojs.getPlugin("hlsQualitySelector")) {
        this.player.hlsQualitySelector({ displayCurrentQuality: true })
      } else {
        console.error("HLS Quality Selector plugin is not available.")
      }
    })

    this.player.on("canplay", () => {
      this.restorePlaybackRate()
      this.restoreVolume()
      this.selectStoredTextTrack()
    })
  }

  setupEventHandlers() {
    this.player.on("ended", this.handleVideoEnded.bind(this))
    this.player.on("ratechange", this.storePlaybackRate.bind(this))
    this.player.on("volumechange", this.storeVolumeSettings.bind(this))
    this.player.audioTracks().addEventListener("change", this.storeAudioTrack.bind(this))
    this.player.textTracks().addEventListener("change", this.storeTextTrack.bind(this))
  }

  async fetchAndLoadVideoData() {
    try {
      const response = await fetch(`/api/v1/videos/${this.vimeoIdValue}`)
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)

      const data = await response.json()
      this.loadVideoDataIntoPlayer(data)

      this.player.one("loadedmetadata", () => {
        this.selectAudioTrack(data)
      })

      this.removeVimeoPlayer()
    } catch (error) {
      console.error("Error fetching video data:", error)
      this.showVimeoPlayerFallback()
    }
  }

  loadVideoDataIntoPlayer(data) {
    this.player.src({ type: "application/x-mpegURL", src: data.hls_playlist_url })
    this.player.load()
  }

  selectAudioTrack(data) {
    const audioTracks = this.player.audioTracks()
    const storedLanguage = localStorage.getItem("audioTrackLanguage")
    const languageToSelect = storedLanguage || data.language_code

    let trackFound = false

    if (languageToSelect) {
      Array.from(audioTracks).forEach((track) => {
        track.enabled = track.language === languageToSelect
        if (track.enabled) trackFound = true
      })
    }

    if (!trackFound && audioTracks.length > 0) {
      audioTracks[audioTracks.length - 1].enabled = true
    }
  }

  removeVimeoPlayer() {
    this.vimeoPlayerOutletElement.remove()
  }

  showVimeoPlayerFallback() {
    this.vimeoPlayerOutletElement.classList.remove("hidden")
    this.element.remove()
  }

  async handleVideoEnded() {
    if (!this.nextChapterUrlValue || !this.autoplayValue) return

    try {
      const response = await get(this.nextChapterUrlValue, { responseKind: "turbo-stream" })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
    } catch (error) {
      console.error("Error loading next chapter:", error)
    }
  }

  storePlaybackRate() {
    localStorage.setItem("playbackRate", this.player.playbackRate())
  }

  restorePlaybackRate() {
    const playbackRate = parseFloat(localStorage.getItem("playbackRate"))
    if (!isNaN(playbackRate)) {
      this.player.playbackRate(playbackRate)
    }
  }

  storeVolumeSettings() {
    localStorage.setItem("volume", this.player.volume())
    localStorage.setItem("muted", this.player.muted())
  }

  restoreVolume() {
    const volume = parseFloat(localStorage.getItem("volume"))
    if (!isNaN(volume)) {
      this.player.volume(volume)
    }
    const muted = localStorage.getItem("muted")
    if (muted !== null) {
      this.player.muted(muted === "true")
    }
  }

  storeAudioTrack() {
    const audioTracks = this.player.audioTracks()
    const enabledTrack = Array.from(audioTracks).find((track) => track.enabled)
    if (enabledTrack) {
      localStorage.setItem("audioTrackLanguage", enabledTrack.language)
    }
  }

  storeTextTrack() {
    const textTracks = this.player.textTracks()
    const showingTrack = Array.from(textTracks).find((track) => track.mode === "showing")
    if (showingTrack) {
      localStorage.setItem("textTrackLanguage", showingTrack.language)
    } else {
      localStorage.removeItem("textTrackLanguage")
    }
  }

  selectStoredTextTrack() {
    const textTracks = this.player.textTracks()
    const storedLanguage = localStorage.getItem("textTrackLanguage")

    Array.from(textTracks).forEach((track) => (track.mode = "disabled"))

    if (storedLanguage) {
      const matchingTrack = Array.from(textTracks).find((track) => track.language === storedLanguage)
      if (matchingTrack) {
        matchingTrack.mode = "showing"
      }
    }
  }

  play() {
    this.player.play().catch((error) => {
      switch (error.name) {
        case "PasswordError":
          console.warn("Cannot play password protected video.")
          break
        case "PrivacyError":
          console.warn("Cannot play this video due to privacy error.")
          break
        case "NotAllowedError":
          this.handleNotAllowedError()
          break
        default:
          console.warn("Error playing video.", error)
          break
      }
    })
  }

  handleNotAllowedError() {
    console.warn("Autoplay prevented by browser policy, waiting for user interaction.")
    const play = () => {
      this.play()
      document.body.removeEventListener("click", play)
    }
    document.body.addEventListener("click", play)
  }

  disconnect() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
