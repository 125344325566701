import { Controller } from "@hotwired/stimulus"
import GLightbox from "glightbox"

export default class extends Controller {
  connect() {
    this.lightbox = GLightbox({
      autoplayVideos: true,
      touchNavigation: true,
      loop: true,
      zoomable: false
    });

  }

  disconnect(){
    this.lightbox = null;
  }
}
