import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countdown-redirect"
export default class extends Controller {
  static values = {
    wait: 10,
    url: 'https://skillmea.cz'
  }
  
  static targets = [
    "timer"
  ]

  connect() {
    this.timerTarget.innerHTML = this.waitValue

    this.countdownRedirect = setInterval(() => {
      // Countdown
      if(this.waitValue > 0){
        this.waitValue --
        if(this.hasTimerTarget){
          this.timerTarget.innerHTML = this.waitValue
        }
      }
      // Redirect to URL
      else{
        // Using location href, so it doesnt get blocked by ad blockers
        window.location.href = this.urlValue;
        clearInterval(this.countdownRedirect)
        this.element.classList.add('hidden')
      }
    }, 1000) 
  }

  // Cleanup
  disconnect(){
    clearInterval(this.countdownRedirect)
  }
}
