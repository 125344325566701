import { Application } from "@hotwired/stimulus"
import Autocomplete from "stimulus-autocomplete"
import Dialog from "@stimulus-components/dialog"
import TextareaAutogrow from "stimulus-textarea-autogrow"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

application.register("autocomplete", Autocomplete)
application.register("dialog", Dialog)
application.register("textarea-autogrow", TextareaAutogrow)

export { application }
